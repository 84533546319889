import {
  Box,
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import resvita from "../../assets/resvita.png";
import BerkeleyIcon from "../../assets/berkeley.png";
import sanIcon from "../../assets/sanFrancisco.jpeg";
import FdaIcon from "../../assets/fda.png";
import NihIcon from "../../assets/nih.png";
import BakarLabsIcon from "../../assets/bakarLabs.png";
import Loreal from "../../assets/loreal.png";
import abbvie from "../../assets/abbio.png";

import { Fonts } from "../../utils/Fonts";
import React from "react";
import { useMediaQuery } from "react-responsive";
import './styles.css'

const styles = {
  heading: {
    ...Fonts.Inter,
    fontSize: "48px",
    fontWeight: 600,
    textAlign: "left",
    "@media (max-width: 600px)": {
      fontSize: "32px",
      color: "#000000",
    },
  },
  text: {
    ...Fonts.Inter,
    fontSize: "20px",
    fontWeight: 500,
    textAlign: "left",
    color: "#000000",
    "@media (max-width: 600px)": {
      fontSize: "16px",
    },
  },
  newsText: {
    ...Fonts.Inter,
    fontSize: '20px',
    fontWeight: 500,
    textAlign: "left",
    color: "#033451",
    "@media (max-width: 600px)": {
      fontSize: "16px",
    },
  },
  lastNewsItem: {
    borderBottom: "none",
  },
  // newsIcon: {
  //   minWidth: "40px",
  //   // marginRight: "16px",
  // },
  newsList: {
    padding: 0,
    margin:0,
    maxHeight: "600px", // Set the max height for the scrollable area
    overflowY: "auto", // Enable vertical scrolling
    overflowX: "hidden", // Hide horizontal scrolling
    WebkitScrollbar: "none", // Hide scrollbar in Webkit-based browsers (Chrome, Safari)
    scrollbarWidth: "none", // Hide scrollbar in Firefox,

  },
  newsImage: {
    width: "100%",
    maxWidth: "400px",
    marginTop: "16px",
    textAlign: "left",
    "@media (max-width: 600px)": {
      maxWidth: "100%",
      margin: "0 auto",
    },
  },
  newsItem: {
    display: "flex",
    alignItems: "center",
    // padding: "10px 0",
    flexWrap: "wrap",
    // padding:10
  },
};

const newsItems = [
  {
    title:
      "Startup ResVita Bio Targets Rare, Deadly Skin Disease",
    icon: <img src={sanIcon} alt="San Icon" />,
    date: "January 2, 2025",
    link: "https://www.bizjournals.com/sanfrancisco/news/2024/12/30/netherton-syndrome-uc-berkeley-bakar-labs-resvita.html?b=1735331290^22463132",
  },
  {
    title: "Fireside Chat With Greg Went And Amin Zargar",
    icon: <img src={BakarLabsIcon}  alt="Bakar Labs Icon" />,
    date: "November 6, 2024",
    link: "https://bakarlabs.berkeley.edu/events/fireside-chat-with-greg-went-and-amin-zargar/",
  },
  {
    title:
      "ResVita Bio Announces Successful FDA Interact Meeting For RVB-003 Therapy For Netherton Syndrome",
    icon: <img src={FdaIcon}  alt="NIH Icon" />,
    date: "October 23, 2024",
    link: "https://www.prnewswire.com/news-releases/resvita-bio-announces-successful-fda-interact-meeting-for-rvb-003-therapy-for-netherton-syndrome-302284045.html",
  },
  {
    title:
      "NIH Awards ResVita Bio A $2M Phase II SBIR Grant For Continuous Protein Therapy For Netherton Syndrome. ",
    icon: <img src={BakarLabsIcon}  alt="Bakar Labs Icon" />,
    date: "September 24, 2024",
    link: "https://www.prnewswire.com/news-releases/national-institutes-of-health-nih-awards-resvita-bio-a-2m-phase-ii-sbir-grant-for-continuous-protein-therapy-for-netherton-syndrome-302256108.html",
  },
  {
    title:
      "ResVita Bio Uses L’OREAL’S Skin Technology To Develop Revolutionary New Skin Treatments.",
    icon: <img src={Loreal}  alt="NIH Icon" />,
    date: "September 19, 2024",
    link: "https://bakarlabs.berkeley.edu/ethical-innovation-resvita-bio-uses-loreals-leading-skin-technology-to-develop-revolutionary-new-skin-treatments/",
  },
  {
    title:
      "NIH Awards ResVita Bio Grant TO Develop Treatment For Severe Atopic Dermatitis.",
    icon: <img src={FdaIcon}  alt="FDA Icon" />,
    date: "September 18, 2023",
    link: "https://bakarlabs.berkeley.edu/resvita-bio-receives-nih-grant-to-develop-breakthrough-treatment-for-severe-atopic-dermatitis/",
  },
  {
    title:
      "ResVita Bio Is A Golden Ticket Award At Bakar Labs, Sponsored By The AbbVie.",
    icon: <img src={abbvie}  alt="Bakar Labs Icon" />,
    date: "May 16, 2023",
    link: "https://bakarlabs.berkeley.edu/nosis-bio-radar-tx-resvita-bio-win-golden-tickets-to-bakar-labs-in-collaboration-with-abbvie/",
  },
  {
    title:
      "NIH awards ResVita Bio Phase I Grant To Develop Treatment For Netherton Syndrome.",
    icon: <img src={NihIcon} alt="NIH Icon" />,
    date: "September 20, 2022",
    link: "https://bakarlabs.berkeley.edu/nih-awards-resvita-bio-an-sbir-grant-to-develop-rvb-001-as-a-treatment-for-netherton-syndrome/",
  },
  {
    title:
      "FDA awards ResVita Bio Rare Pediatric Disease Designation For Treatment Of Netherton Syndrome.",
    icon: <img src={FdaIcon}  alt="FDA Icon" />,
    date: "June 9, 2022",
    link: "https://bakarlabs.berkeley.edu/resvita-bio-receives-rare-pediatric-disease-designation-for-rvb-001-as-a-treatment-for-netherton-syndrome/#:~:text=ResVita%20Bio%2C%20a%20cellular%20therapies,life%2Dthreatening%20genetic%20skin%20disease.",
  },
  {
    title:
      "The story of ResVita Bio: How A Baker Fellows Passion For Cell Therapies Become A Reality.",
    icon: <img src={BerkeleyIcon} alt="Berkeley Icon" />,
    date: "May 25, 2022",
    link: "https://bakarfellows.berkeley.edu/entrepreneurial-journey/the-story-of-resvita-bio-how-a-bakar-fellows-passion-became-a-reality/", // Add the link here
  },
];

const NewsSection = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleClick = (url) => {
    window.open(url, "_blank"); // Open the link in a new tab
  };

  return (
    <Box
      sx={{ py: 5, bgcolor: "white", overflowX: "hidden" }}
      style={{ marginTop: 50 }}
      id="News"
    >
      <Container>
        <Grid container spacing={4}>
          {/* Left Section with Image */}
          <Grid item xs={12} md={6}>
            <Typography gutterBottom style={{...styles.heading, textAlign:isMobile ?'center' : 'left'}}>
              NEWS
            </Typography>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ ...styles.text,textAlign:isMobile ?'center' : 'left'}}
            >
              Continuous Impact: Updates and Innovations
            </Typography>
            <div style={{ display: isMobile ? "" : 'flex' }}>
              <Box
                component="img"
                src={resvita}
                alt="News Image"
                style={{
                  ...styles.newsImage,
                  width: isMobile ? "70%" : "100%",
                }}
              />
            </div>
          </Grid>

          {/* Right Section with Scrollable News Items */}
          <Grid item xs={12} md={6}>
  <List style={styles.newsList}>
    {newsItems.map((item, index) => (
      <React.Fragment key={index} >
        <div  className="news-item-wrapper"
                      onClick={() => handleClick(item.link)}
                      style={{cursor:'pointer'}}>
          <ListItem
            style={{
              ...styles.newsItem,
              ...(index === newsItems.length - 1 ? styles.lastNewsItem : {}),
            }}
            
          >
            <text
              style={{
                ...Fonts.Inter,
                fontSize: 12,
                fontWeight: 600,
                color: "#033451",
                marginBottom: 15,
              }}
            >
              {item.date}
            </text>
            <text style={styles.newsText}>{item.title}</text>
          </ListItem>
          <ListItem
            style={{
              ...styles.newsItem,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ListItemIcon style={styles.newsIcon}>
              {item.icon}
            </ListItemIcon>
            <EastIcon
              sx={{
                color: "#0099E6",
                cursor: "pointer",
                width: 45,
                height: 35,
              }}
             className="news-arrow"/>
          </ListItem>
        </div>
      </React.Fragment>
    ))}
  </List>
</Grid>


        </Grid>
      </Container>
    </Box>
  );
};

export default NewsSection;
